<template>
  <div class="relative max-w-screen w-full flex justify-center items-center px-5 md:py-10 pt-0 pb-5 overflow-hidden z-auto">
    <div class="relative w-full max-w-screen-lg flex items-center rounded bg-white shadow-xl p-10 lg:p-20 mx-auto text-gray-800 md:text-left">
      <div class="md:flex items-center">
        <div class="w-full md:w-1/2 px-5 mb-10 md:mb-0">
          <div class="relative">
            <img
              src="/img/logo.png"
              class="w-full relative"
              style="z-index:1"
              alt=""
            >
            <div class="border-4 border-yellow-200 absolute top-10 bottom-10 left-10 right-10 z-0" />
          </div>
        </div>
        <div class="w-full md:w-1/2 px-10">
          <div class="mb-10">
            <h1 class="font-bold uppercase text-2xl mb-5">
              Mille et 1 pains <br>Cedric Q.
            </h1>
            <p class="text-sm">
              Lorem ipsum dolor sit, amet consectetur adipisicing, elit. Eos, voluptatum dolorum! Laborum blanditiis consequatur, voluptates, sint enim fugiat saepe, dolor fugit, magnam explicabo eaque quas id quo porro dolorum facilis...
            </p>
          </div>
          <div>
            <div class="inline-block align-bottom hidden md:block">
              <router-link
                to="/contact"
                class="bg-yellow-300 opacity-75 hover:opacity-100 text-yellow-900 hover:text-gray-900 rounded-full px-10 py-2 font-semibold"
              >
                <i class="mdi mdi-cart -ml-2 mr-2" /> Contactez-moi
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'About',
  layout: 'default'
}
</script>
